import React from 'react';

import { Footer } from '../footer';
import * as Sections from './sections';
import { Aside, Section } from './LandingPage.StyledComponents';
import { PriceStrip } from '../LandingPage';

const LandingPage = () => (
  <React.Fragment>
    <Section>
      <Sections.Hero />
    </Section>
    <Aside>
      <PriceStrip as={"div"} />
    </Aside>
    <Section>
      <Sections.TheCommunity />
    </Section>
    <Section autoLayout={true}>
      <Sections.TopSales />
    </Section>
    <Section autoLayout={true} style={{ position: 'relative', overflow: 'hidden' }}>
      <Sections.HowNamebaseWorks />
    </Section>
    <Section autoLayout={true}>
      <Sections.DecentralizedDns />
    </Section>
    <Section autoLayout={true}>
      <Sections.BackersAndAdopters />
    </Section>
    <Section as="div" autoLayout={true}>
      <Sections.Marketplace />
    </Section>
    <Footer />
  </React.Fragment>
);

export { LandingPage };
