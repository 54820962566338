import React from 'react';
import styled from 'styled-components';

import { DisplayLarge } from '../../text';
import { Frame, ctaAnchorBaseStyles } from '../LandingPage.StyledComponents';

export const FrameWithMargin = styled(Frame)`
  margin-top: ${({ theme }) => theme.spacing(11)};
  margin-bottom: ${({ theme }) => theme.spacing(11)};
`;

export const CenterAlign = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CtaAnchor = styled.a.attrs(props => ({
  children: (
    <React.Fragment>
      <DisplayLarge as="p" weight="bold">
        {props.children}
      </DisplayLarge>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.6667 28.3333L31 20L22.6667 11.6667"
          strokeWidth="4.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1985 20.0585L9.83325 20.0585"
          strokeWidth="4.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </React.Fragment>
  ),
}))`
  ${ctaAnchorBaseStyles};
  margin-top: ${({ theme }) => theme.spacing(16)};
  margin-bottom: ${({ theme }) => theme.spacing(16)};

  @media ${({ theme }) => theme.breakpoints.md} {
    ${DisplayLarge} {
      font-size: ${({ theme }) => theme.text.headerLarge.size};
      line-height: ${({ theme }) => theme.text.headerLarge.lineHeight};
    }
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    margin-top: ${({ theme }) => theme.spacing(6)};
    margin-bottom: ${({ theme }) => theme.spacing(6)};

    svg {
      width: 32px;
    }
    path {
      stroke-width: 2.5;
    }

    ${DisplayLarge} {
      font-size: ${({ theme }) => theme.text.regular.size};
      line-height: ${({ theme }) => theme.text.regular.lineHeight};
    }
  }
`;
