import React from 'react';
import styled from "styled-components";
import { Large } from '../../text';

export const Galleries = styled.div`
  width: 100%;
  display: grid;
  margin-top: ${({ theme }) => theme.spacing(10)};
  grid-template-columns: 1fr 1fr;

  @media ${({ theme }) => theme.breakpoints.md} {
    margin-top: ${({ theme }) => theme.spacing(7)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-template-columns: 1fr;

    & > * + * {
      margin-top: ${({ theme }) => theme.spacing(13)};
    }
  }
`;

export const Gallery = styled.div.attrs(props => ({
  children: (
    <div className="gallery-root">
      <Large align="center" weight="bold" transform="uppercase">{props.heading}</Large>
      <div className="gallery-items">
        {props.children}
      </div>
    </div>
  )
}))`
  ${Large} {
    letter-spacing: 1.5px;
    padding-bottom: ${({ theme }) => theme.spacing(10)};
  }

  .gallery-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    ${Large} {
      font-size: ${({ theme }) => theme.text.regular.size};
      line-height: ${({ theme }) => theme.text.regular.lineHeight};
      padding-bottom: ${({ theme }) => theme.spacing(6)};
    }
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    .gallery-items div:nth-child(odd) {
      margin-left: 0;
    }
    .gallery-items div:nth-child(even) {
      margin-right: 0;
    }
  }
`;

export const GalleryItem = styled.div`
  width: 50%;
  height: max-content;
  margin: ${({ theme }) => theme.spacing(3)};
  max-width: 216px;
  margin-top: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(4)}; 

  @media ${({ theme }) => theme.breakpoints.md} {
    width: calc(50% - 48px);
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    width: calc(50% - 32px);
  }
`;