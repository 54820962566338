import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Large } from '../../text';
import { TweetEmbedLookalike } from './components/TweetEmbedLookalike';
import { Cta, TweetReel, ReelItem, TweetReelOverflow } from './TheCommunity.StyledComponents';

const TheCommunityQuery = graphql`
  query TheCommunityQuery {
    content: contentfulLandingPageV3 {
      ...TheCommunityContent
    }
  }
`;

const TheCommunity = () => {
  const { content } = useStaticQuery(TheCommunityQuery);

  return (
    <React.Fragment>
      <Cta href={content.communityCtaHref}>
        <Large as="h2">{content.communityCtaLabel}</Large>
      </Cta>
      <TweetReelOverflow>
        <TweetReel>
          {content.tweets.map((tweet, index) => (
            <ReelItem key={tweet.id} css={{ gridArea: `tweet${index}` }}>
              <TweetEmbedLookalike {...tweet} />
            </ReelItem>
          ))}
        </TweetReel>
      </TweetReelOverflow>
    </React.Fragment>
  );
};

export { TheCommunity };
