import Img from 'gatsby-image';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
  H1,
  H2,
  Input,
  CtaForm,
  CtaContent,
  IphoneImage,
  AlignContent,
  BackgroundSvg,
  WidthAndHeight,
} from './Hero.StyledComponents';
import { Button } from '../../Button';

const HeroQuery = graphql`
  query HeroQuery {
    content: contentfulLandingPageV3 {
      ...HeroContent
    }
  }
`;

const Hero = () => {
  const { content } = useStaticQuery(HeroQuery);
  const [formValue, setFormValue] = React.useState('');

  const onFormSubmit = e => {
    e.preventDefault();
    const encodedFormValue = encodeURIComponent(formValue);
    window.location = `${content.heroCtaButtonHref}?email=${encodedFormValue}`;
  };

  return (
    <WidthAndHeight>
      <AlignContent>
        <CtaContent>
          <H1 weight="bold" colored="nbPrimary">{content.h1}</H1>
          <H2>{content.h2}</H2>
          <CtaForm onSubmit={onFormSubmit}>
            <Input
              type="text"
              value={formValue}
              onChange={e => setFormValue(e.target.value)}
              placeholder={content.heroCtaInputPlaceholder}
            />
            <Button size="large" type="submit">
              {content.heroCtaButtonLabel}
            </Button>
          </CtaForm>
        </CtaContent>
        <IphoneImage>
          <Img
            alt={content.heroImage.description}
            fluid={content.heroImage.fluid}
            loading="eager"
            durationFadeIn={250}
          />
        </IphoneImage>
      </AlignContent>
      <BackgroundSvg />
    </WidthAndHeight>
  );
};

export { Hero };
