import Img from 'gatsby-image';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
  Step,
  StepTitle,
  StepNumber as StepCount,
  StepDescription,
} from '../../LandingPage/HowItWorks/HowItWorks.StyledComponents';
import {
  H2,
  StyledCta,
  ImageSize,
  ImagePosition,
  DescriptionList,
  DescriptionTerm,
} from './HowNamebaseWorks.StyledComponents';
import { Large } from '../../text';
import { Divider } from '../../LandingPage/Divider';

const HowNamebaseWorksQuery = graphql`
  query HowNamebaseWorksQuery {
    content: contentfulLandingPageV3 {
      ...HowNamebaseWorksContent
    }
  }
`;

const HowNamebaseWorks = () => {
  const { content } = useStaticQuery(HowNamebaseWorksQuery);

  return (
    <React.Fragment>
      <H2>{content.howItWorksHeading}</H2>
      <Divider />
      <DescriptionList>
        {content.items.map((item, index) => (
          <Step key={`how-it-works-${index}`}>
            <DescriptionTerm>
              <StepCount as="span">
                {String(index + 1).padStart(2, '0')}
              </StepCount>
              <StepTitle as="span">
                {item.title}
              </StepTitle>
            </DescriptionTerm>
            <StepDescription as="dd">{item.description}</StepDescription>
          </Step>
        ))}
      </DescriptionList>
      <StyledCta href={content.howItWorksCtaHref}>
        <Large>{content.howItWorksCtaLabel}</Large>
      </StyledCta>
      <ImageSize>
        <ImagePosition>
          <Img
            alt={content.image.description}
            fluid={content.image.fluid}
            fadeIn={false}
            loading="eager"
          />
        </ImagePosition>
      </ImageSize>
    </React.Fragment>
  );
};

export { HowNamebaseWorks };
