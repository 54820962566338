import styled from 'styled-components';
import { Large, Regular } from '../../text';
import { Button } from '../../Button';

export const PriceStripAside = styled.aside`
  display: flex;
  padding: ${({ theme: { spacing } }) => `${spacing(3.5)} ${spacing(6)}`};
  box-shadow: 0px -32px 24px -16px rgba(17, 17, 17, 0.5);
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.white};

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: ${({ theme: { spacing } }) => `${spacing(2)} ${spacing(3)}`};
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const HandshakeText = styled(Large)`
  color: ${({ theme }) => theme.color.handshakeLogoBlack};
  margin-left: ${({ theme }) => theme.spacing(2)};

  span {
    font-weight: 500;
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    span {
      display: none;
    }
  }
`;

export const Prices = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.spacing(5)};

  font-size: ${({ theme }) => theme.text.large.size};
  line-height: ${({ theme }) => theme.text.large.lineHeight};

  @media ${({ theme }) => theme.breakpoints.sm} {
    flex-direction: column;
    font-size: ${({ theme }) => theme.text.medium.size};
    line-height: ${({ theme }) => theme.text.medium.lineHeight};
    align-items: flex-end;
    height: max-content;
  }
`;

export const Currency = styled(Regular).attrs(props => ({ colored: 'grey90' }))`
  margin-left: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing(4)};
  @media ${({ theme }) => theme.breakpoints.sm} {
    margin-right: 0;
  }
`;

export const Graph = styled.div`
  height: 100%;
  max-height: 100%;
  & > * {
    max-height: 100%;
  }
  margin-right: ${({ theme }) => theme.spacing(4)};
  @media ${({ theme }) => theme.breakpoints.md} {
    display: none;
  }
`;

export const BuyButton = styled(Button).attrs(props => ({
  as: 'a',
  href: '/buy',
  variant: 'secondary',
}))`
  @media ${({ theme }) => theme.breakpoints.sm} {
    display: none;
  }
`;
