import React from 'react';
import styled from 'styled-components';
import { Huge, DisplayLarge } from '../../text';

export const WidthAndHeight = styled.div`
  width: 100%;
  height: 640px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  max-width: 1440px;
  padding-top: ${({ theme }) => theme.spacing(3)};

  @media ${({ theme }) => theme.breakpoints.md} {
    height: 656px;
    margin: none;
    max-width: 100%;
    padding-top: ${({ theme }) => theme.spacing(5)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    height: max-content;
    margin-top: ${({ theme }) => theme.spacing(11)};
    padding-top: 0;
    padding-bottom: ${({ theme }) => theme.spacing(6)};
  }
`;

export const AlignContent = styled.div`
  width: 100%;
  display: flex;
  padding-left: calc((100vw - 152px) / 6.70);
  padding-right: calc((100vw - 688px) / 6.70);
  justify-content: space-between;

  @media ${({ theme }) => theme.breakpoints.xl} {
    padding-left: 192px;
    padding-right: 112px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    width: max-content;
    padding: 0;
    padding-left: calc((100vw - 632px) / 2.85);
    justify-content: flex-start;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const CtaContent = styled.div`
  width: 488px;

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 389px;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
    margin: 0 auto;
    max-width: 597px;
  }
`;

export const H1 = styled(DisplayLarge).attrs(props => ({ as: 'h1' }))`
  font-size: 56px;
  line-height: 64px;
  padding-top: 150px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: 48px;
    line-height: 56px;
    padding-top: 0;
  }
`;
export const H2 = styled(Huge).attrs(props => ({ as: 'h2' }))`
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const CtaForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    flex-direction: column;
    button {
      font-size: 18px;
      margin-top: ${({ theme }) => theme.spacing(1)};
      margin-left: 0;
    }
  }
`;

export const Input = styled.input`
  flex: 1;
  border: 1px solid ${({ theme }) => theme.color.grey30};
  font-size: ${({ theme }) => theme.text.regular.size};
  line-height: ${({ theme }) => theme.text.regular.lineHeight};
  padding-top: ${({ theme }) => theme.spacing(1.5)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.borderRadii.standard};
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: 18px;
    -webkit-appearance: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.grey50};
  }
`;

export const BackgroundSvg = styled.div.attrs(props => ({
  children: (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="997"
      height="642"
      viewBox="0 0 997 642">
      <path
        d="M1 641L23.3507 624.642L30.3353 606.24H35.923L49.892 579.658L58.2735 616.463H73.6397L82.0211 606.24L95.9903 600.105L105.769 606.24H112.753L119.738 616.463L136.501 600.105L163.042 624.642L202.156 616.463L209.14 610.329L216.125 616.463L223.11 634.866L248.254 616.463L262.223 624.642L269.208 634.866L285.971 624.642L304.13 634.866L312.512 616.463L323.687 624.642L339.053 606.24H347.435L362.801 579.658H371.182L378.167 583.748H396.327L403.311 542.853L413.09 555.121L422.868 548.987L428.456 518.316L435.44 548.987H445.219L452.204 524.45L459.188 542.853H466.173L475.951 559.211L480.142 454.93L489.92 565.345H496.905L505.286 583.748L510.874 559.211L520.652 571.479L527.637 587.837L536.018 571.479H549.988L556.972 587.837H572.338L579.323 559.211L586.307 571.479L594.689 548.987L603.07 555.121L610.055 510.137L626.818 483.556L633.802 465.153L640.787 454.93L657.55 477.422L663.137 469.243H672.916L681.298 465.153L685.488 424.259L710.633 389.498L716.22 234.099L732.983 274.994H742.762L753.937 289.307L760.922 274.994L766.509 299.53L777.685 274.994L787.463 309.754L793.05 299.53L801.432 309.754L818.195 328.157L830.767 124.254L839.149 328.157L853.118 299.53L861.499 309.754L868.484 254.546L885.247 309.754L897.819 274.994H906.201L913.185 234.099L921.567 274.994L936.933 234.099L943.918 242.278L959.283 69.046L964.871 254.546L974.649 225.92L981.634 162.534L990.016 140.042L997 1"
        stroke={props.theme.color.nbPrimary}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
}))`
  top: 0;
  right: 0;
  z-index: -1;
  opacity: 0.4;
  position: absolute;

  @media ${({ theme }) => theme.breakpoints.md} {
    top: ${({ theme }) => theme.spacing(2)};
  }
`;

export const IphoneImage = styled.div`
  width: 535px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    display: none;
  }
`;
